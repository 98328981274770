<div class="layout-grid" [class.layout-grid_wide]="!showAsideRight">
  <div class="layout-grid__aside-left">
    <ng-content select="[aside-left]" />
    <ng-content select="[notifications]" />
  </div>
  <div class="layout-grid__main">
    <ng-content select="[main]" />
  </div>
  @if (showAsideRight) {
    <div class="layout-grid__aside-right">
      <ng-content select="[aside-right]" />
    </div>
  }
</div>
