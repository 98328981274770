import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DetailPageAsideItem } from '../model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'expocab-detail-page-aside',
  standalone: true,
  styleUrl: './detail-page-aside.component.scss',
  templateUrl: './detail-page-aside.component.html',
})
export class DetailPageAsideComponent {
  @Input({ required: true }) items!: DetailPageAsideItem[];
}
