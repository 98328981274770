import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  booleanAttribute,
} from '@angular/core';

export type ItemPlace = 'center' | 'end' | 'start' | 'stretch';

function tupleToString(tuple: [ItemPlace, ItemPlace]): string {
  return tuple.join(' ');
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'expocab-layout-grid',
  standalone: true,
  styleUrl: './layout-grid.component.scss',
  templateUrl: './layout-grid.component.html',
})
export class LayoutGridComponent {
  @HostBinding('style.--place-self-left')
  @Input({ transform: tupleToString })
  asideLeftPlace!: [ItemPlace, ItemPlace]; // [align-self, justify-self]

  @HostBinding('style.--place-self-right')
  @Input({ transform: tupleToString })
  asideRightPlace!: [ItemPlace, ItemPlace]; // [align-self, justify-self]

  @Input({ transform: booleanAttribute }) showAsideRight!: boolean;
}
