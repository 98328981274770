@if (title) {
  <h3 class="title expocab-h3-heading">{{ title }}</h3>
}
<div class="detail-page-grid">
  <expocab-circle-button
    class="back-button"
    size="big"
    type="white"
    icon="back-arrow"
    (click)="back()"
  />

  <div class="detail-page-grid__main">
    <ng-content />
  </div>

  @if (items) {
    <expocab-detail-page-aside
      class="detail-page-grid__aside"
      [items]="items"
    />
  }
</div>
