<div class="aside">
  @for (item of items; track $index; let first = $first; let last = $last) {
    <div
      class="aside__block"
      [class.aside__block_first]="first"
      [class.aside__block_last]="last"
    >
      <div class="aside__block-title">{{ item.title }}</div>
      <div
        class="aside__block-text expocab-text-medium"
        [innerHTML]="item.text"
      ></div>
    </div>
  }
</div>
