import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  booleanAttribute,
} from '@angular/core';
import { FiltersService, SidemenuService } from '@expocab/shared/api';
import { CircleButtonComponent } from '@expocab/ui/buttons/circle-button';

import {
  DetailPageAsideComponent,
  DetailPageAsideItem,
} from '../../detail-page-aside';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DetailPageAsideComponent, CircleButtonComponent],
  selector: 'expocab-detail-page-grid',
  standalone: true,
  styleUrl: './detail-page-grid.component.scss',
  templateUrl: './detail-page-grid.component.html',
})
export class DetailPageGridComponent implements OnInit {
  // eslint-disable-next-line perfectionist/sort-classes
  @Output() backEvent = new EventEmitter<void>();

  @HostBinding('class.detail-page-grid-centered')
  @Input({ transform: booleanAttribute })
  centered!: boolean;

  @Input() items!: DetailPageAsideItem[];
  @Input() title!: string;

  constructor(
    private location: Location,
    private sidemenuService: SidemenuService,
    private filtersService: FiltersService,
    private destroyRef: DestroyRef,
  ) {
    this.destroyRef.onDestroy(() => {
      this.sidemenuService.set('state', true);
      this.filtersService.set('state', true);
    });
  }

  back(): void {
    this.location.back();
  }

  ngOnInit(): void {
    this.sidemenuService.set('state', false);
    this.filtersService.set('state', false);
  }
}
